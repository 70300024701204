<template>
    <PageIntro v-editable="blok" :data="pageIntroData" />
</template>

<script setup lang="ts">
import PageIntro from '~/components/page-building/PageIntro.vue';
import PageIntroType from '~/types/PageIntroType';
import ButtonType from '~/types/ButtonType';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const pageIntroData = computed<PageIntroType>(() => {
    return {
        heading: props.blok.heading,
        content: richTextToHtml(props.blok.content) as string,
        buttons: props.blok.buttons
            ? props.blok.buttons.map((button: any) => {
                  return {
                      label: button.label,
                      style: button.style,
                      url: button.link.cached_url,
                      vEditable: button,
                  } as ButtonType;
              })
            : [],
        backgroundColor: props.blok.background_color,
        vertical: props.blok.vertical,
        textColor: props.blok.text_color,
        nextBackgroundColor: props.nextBlock
            ? props.nextBlock.background_color
            : null,
        bottomAngle: props.blok.bottom_angle,
        vEditable: props.blok,
    };
});
</script>
